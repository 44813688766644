import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";

const query = graphql`
  query BooksMenu {
    books: allMarkdownRemark(
      limit: 3
      sort: { fields: frontmatter___highlight, order: ASC }
      filter: { fileAbsolutePath: { regex: "//content/books//" } }
    ) {
      totalCount
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

const Header = ({ siteTitle, description }) => (
  <section id="header">
    <div className="container">
      <h1 id="logo">
        <Link to="/">{siteTitle}</Link>
      </h1>
      <p>{description}</p>
      <nav id="nav">
        <ul>
          <li>
            <Link className="icon fa-home" to="/">
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/blog" className="icon fa-file-text-o">
              <span>Blog</span>
            </Link>
          </li>
          <li>
            <Link className="icon fa-book" to="/books">
              <span>Books</span>
            </Link>
            {/*
            <ul className="dropotron level-0">
              <StaticQuery
                query={query}
                render={({ books: { edges } }) =>
                  edges.map(({ node: { fields: { path }, frontmatter: { title } } }) => (
                    <li key={path}>
                      <Link to={path}>{title}</Link>
                    </li>
                  ))
                }
              />
            </ul>
            */}
          </li>
          <li>
            <Link className="icon fa-user" to="/about">
              <span>About the Author</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </section>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ""
};

export default Header;
