import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import '../../strongly-typed/assets/sass/main.scss';
import { Footer } from './footer';

const Layout = ({ bodyClass = 'right-sidebar', children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
          <body className={bodyClass} />
        </Helmet>
        <div id="page-wrapper" style={{ backgroundColor: 'white' }}>
          <Header siteTitle={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
          {children}
          <Footer/>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
