import React from 'react';

export const Footer = () => {
  return (
    <section id="footer">
      <div id="copyright" className="container">
        <ul className="links">
          <li>© Malissa Kent. All rights reserved.</li>
          <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
          <li>Powered by <a href="https://www.gatsbyjs.org">Gatsby</a></li>
        </ul>
      </div>
    </section>
  )
};
